const Images = {
  logo: "images/logo.svg",
  testimony: "images/testimony.png",
  piggy: "images/piggy.png",
  menuRepo: "images/menu-svgrepo.png",
  menu: "images/menu.svg",
  logoBottom: "images/logo_bottom.png",
  fbIcon: "images/fb.png",
  bg1: "images/bg1.png",
  bg2: "images/bg2.png",
  CHAR: "images/CHAR.png",
  christmas: "images/christmas.png",
  bgSlidermb: "images/bgSlidermb.png",
  bgGame: "images/bgGame.png",
  bgAbout: "images/bgAbout.png",
  work1: "images/work1.png",
  work2: "images/work2.png",
  work3: "images/work3.png",
  work4: "images/work4.png",
  person: "images/person.png",
  submit: "images/Vector.png",
  clientBg: "images/client.png",
  ironsource: "images/ironsource.png",
  mintegrallogo: "images/mintegrallogo.png",
  bgEllipse1: "images/Ellipse 14.png",
  bgEllipse2: "images/Ellipse 15.png",
};
export default Images;
